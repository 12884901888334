<template>
  <div class="border-bot">
    <LoginStatus :full-width="true" :testid="testid" @click="$emit('close')">
      <div
        v-if="userContext.initialized"
        class="flex flex-row px-md gap-sm py-md"
      >
        <UserIcon :size="50" />
        <div data-testid="userSalutation">
          <div>{{ t('user.loginStatus.salutation') }}</div>
          <div class="font-bold">
            {{ userContext.userData?.name1 ?? 'error loading user data' }}
          </div>
        </div>
      </div>
    </LoginStatus>
  </div>
</template>
<script setup lang="ts">
import { LoginStatus } from '@/complib';
import UserIcon from '@/components/user/userIcon/userIcon.vue';
import { useUserContext } from '~/stores/useUserContext';
defineEmits<{
  (e: 'close'): void;
}>();
defineProps({
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

const { t } = useTrans();
const userContext = useUserContext();
</script>
<style scoped lang="postcss">
.border-bot {
  @apply relative;
  &::after {
    content: '';
    @apply border-b-[1px] absolute bottom-0 border-solid border-vdv-border-light h-[1px] ml-md w-[calc(100%-60px)];
  }
}
</style>
