<template>
  <!--If level 0-->
  <Transition
    v-if="state.navItems.length > 0 && state.show && showBigNav"
    name="fade"
    @mouseover="emit('hover')"
  >
    <div
      id="hovermenu"
      class="flex-row hoverNav"
      :style="
        showBigNav
          ? 'left:0px; padding-bottom:45px;'
          : `left:${state.x_pos}px; width:${state.width}px; height:auto; padding-bottom:15px;`
      "
      @click="emit('close')"
    >
      <div
        class="flex flex-row w-full max-w-screen-xl mx-auto item-start flex-nowrap"
      >
        <div
          class="flex flex-col flex-nowrap content-start h-[370px]"
          :class="state.hoveredItem.ad ? 'w-[500px]' : 'w-full'"
        >
          <!-- Nav Item Overview Link; Only if Level 0-->
          <NuxtLink
            :to="navParent.navUri"
            class="w-full max-w-[500px] font-bold item"
            @click="emit('linkClick')"
          >
            <span v-if="navParent.icon" :key="navParent.id" class="mr-xs">
              <FaIcon :icon="navParent.icon" classes="fa-fw" /></span
            ><span
              >{{ t('toggleNav.overview') }}
              {{ t(state.navItems[0]?.parent.title) }}</span
            >
          </NuxtLink>
          <div
            class="border-b-[1px] max-w-[500px] bottom-0 border-solid border-vdv-border-light h-[1px] w-full ml-xs mt-xs mb-sm"
          ></div>
          <div class="w-[450px] h-[100%] flex flex-col flex-wrap">
            <!-- Nav Items-->
            <div v-for="navitem of state.navItems" :key="navitem.id">
              <NuxtLink
                class="item"
                :to="navitem.navUri"
                :data-testid="generateTestIdFromUrl(navitem.navUri)"
                :class="{
                  'bg-vdv-base text-white': navitem === state.hoveredItem,
                  'font-bold w-[210px]': navitem.children.length,
                  'font-bold mb-sm w-[210px]': !navitem.children.length,
                }"
                @click="emit('linkClick')"
              >
                {{ t(navitem.title) }}
              </NuxtLink>
              <NuxtLink
                v-for="child of getChildrenOfNavItem(navitem)"
                :key="child.id"
                class="item font-normal w-[210px] last:mb-sm !py-[8px] leading-[22px]"
                :to="child.navUri"
                :data-testid="generateTestIdFromUrl(child.navUri)"
                @click="emit('linkClick')"
              >
                <span>{{ t(child.title) }}</span>
              </NuxtLink>

              <div
                v-if="navitem.children.length > 5 && navitem.isShopItem"
                class="w-[210px] text-md text-vdv-base my-xs pl-xs"
              >
                <NuxtLink
                  :to="navitem.navUri"
                  :data-testid="generateTestIdFromUrl(navitem.navUri)"
                  target="_self"
                  class="hover:underline vdv-anim-link"
                >
                  <span v-if="navitem.showAllBtnText">{{
                    t(navitem.showAllBtnText)
                  }}</span
                  ><span v-else>{{
                    t('header.navigation.showMoreCategories', {
                      category: t(navitem.title),
                    })
                  }}</span
                  ><span class="whitespace-nowrap"
                    >&nbsp;<FaIcon icon-class="fas fa-chevron-right" />
                  </span>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="state.hoveredItem.ad"
          class="w-full h-full ml-md py-md mt-[20px]"
        >
          <template v-if="assignedBanners.length">
            <div v-if="assignedBanners[0]" :key="assignedBanners[0]?.banner.id">
              <Banner
                :key="assignedBanners[0].banner.id"
                :banner-data="assignedBanners[0].banner"
              />
            </div>
          </template>
          <template v-else>
            <NuxtLink
              v-if="state.hoveredItem.ad && state.hoveredItem.ad.link"
              :to="state.hoveredItem.ad.link"
              class="h-full"
            >
              <ImageBasic
                :image-data="state.hoveredItem.ad.image"
                classes="max-h-full"
              ></ImageBasic>
            </NuxtLink>
            <div v-else-if="state.hoveredItem.ad" class="h-full">
              <ImageBasic
                :image-data="state.hoveredItem.ad.image"
                classes="max-h-full"
              ></ImageBasic>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Transition>
  <!--If Not level 0-->
  <Transition
    v-else-if="state.navItems.length > 0 && state.show && !state.isLevel_0"
    name="fade"
    @mouseover="emit('hover')"
  >
    <div
      id="hovermenu"
      class="flex-col hoverNav flex-nowrap"
      :style="`left:${state.x_pos}px; width:${state.width}px; height:auto; padding-bottom:15px;`"
      @click="emit('close')"
    >
      <div v-for="navitem of state.navItems" :key="navitem.id">
        <NuxtLink
          class="item"
          :to="navitem.navUri"
          :class="{
            'bg-vdv-base text-white': navitem === state.hoveredItem,
            'font-bold w-[210px]': state.isLevel_0 && navitem.children.length,
            'font-bold mb-sm w-[210px]':
              state.isLevel_0 && !navitem.children.length,
          }"
          @click="emit('linkClick')"
        >
          {{
            navitem.id == 'myAccount' ? t(userType.linkText) : t(navitem.title)
          }}
        </NuxtLink>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { type LinkedNavItem } from '@/stores/useNav';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import FaIcon from '@/components/fa-icon.vue';
import { useDashboardDynamicLink } from '@/composables/useDashboardDynamicLink';
import { useBannerStore } from '~/stores/useBannerStore';
import { BannerPositions } from '~/@types/bannerPositions';
import { resolveBannerAssignment } from '~/composables/banner/resolver/resolveAssignment';
import { useSessionStore } from '~/stores/useSessionStore';
import Banner from '@/components/banner/banner.vue';

const userType = useDashboardDynamicLink();

const props = defineProps({
  state: {
    type: Object as PropType<{
      isHovered: boolean;
      show: boolean;
      hoveredItem: LinkedNavItem;
      navItems: LinkedNavItem[];
      isLevel_0: boolean;
      x_pos: number;
      width: number;
    }>,
    required: true,
  },
});
function generateTestIdFromUrl(url: string) {
  if (url[0] === '/') {
    url = url.slice(1, url.length);
  }
  if (url.includes('/')) {
    const urlParts1 = url.split('/').map((part) => {
      const part11 = part[0].toUpperCase() + part.substring(1, part.length);
      return part11;
    });
    url = urlParts1.join('');
  }
  if (url.includes('-')) {
    const urlParts = url.split('-').map((part) => {
      const part1 = part[0].toUpperCase() + part.substring(1, part.length);
      return part1;
    });
    url = urlParts.join('');
  }
  return `navItem${url}`;
}

const emit = defineEmits<{
  (e: 'hover'): void;
  (e: 'linkClick'): void;
  (e: 'close'): void;
}>();

//const navStore = useNav();
const bannerStore = useBannerStore();

const navParent = computed(() => {
  return props.state.navItems.length > 0
    ? props.state.navItems[0]?.parent
    : null;
});

const showBigNav = computed(() => {
  return true;
  /**
   * @INFO workaround for showing only the toplevel navItems
   */
  //return (
  //  props.state.isLevel_0 ||
  //  (navStore.mainNav.activeItem.children.length === 0 &&
  //    navStore.mainNav.activeItem.parent.level === 0)
  //);
});

function getChildrenOfNavItem(navitem: LinkedNavItem) {
  if (navitem.children.length > 5 && navitem.isShopItem) {
    return navitem.children.slice(0, 5);
  }

  return navitem.children;
}

const assignments = await bannerStore.getBannerAssignments(
  BannerPositions.NAV_FLYOUT,
);

const assignedBanners = computed(() => {
  const uri = props.state.hoveredItem.navUri.replace('/', '');
  return resolveBannerAssignment(assignments, BannerPositions.NAV_FLYOUT, {
    uri: uri ? uri : 'startseite',
    isLoggedIn: useSessionStore().isLoggedIn,
  });
});

const { t } = useTrans();
</script>
<style scoped lang="postcss">
.hoverNav {
  @apply absolute overflow-hidden flex h-[480px] pt-sm pb-lg top-[60px] min-w-[250px] left-0 w-[100%] bg-white z-[530] shadow-lg opacity-100;
  box-shadow: 0px 8px 10px 0px rgb(0, 0, 0, 0.1);
}
.item {
  @apply flex py-xs p-xs hover:bg-vdv-alpha hover:text-vdv-font transition-colors;
}

.fade-enter-active,
.fade-leave-active {
  @apply duration-200 opacity-100;
  transition-property: color, background-color, opacity, height;
}

.fade-enter-from,
.fade-leave-to {
  transition-property: color, background-color, height;
  @apply transition-none h-0 py-0 opacity-0;
}
</style>
