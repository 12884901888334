<template>
  <div
    data-testid="nav"
    class="relative w-full h-[60px] bg-vdv-light print:hidden"
    @mouseleave="hoverMenuUnhover()"
  >
    <div
      class="relative w-full h-[60px] flex flex-row flex-nowrap bg-vdv-light pl-sm max-w-screen-2xl mx-auto"
    >
      <div
        class="flex flex-row items-center w-full md:w-[120px] xl:w-[180px] justify-between"
      >
        <div
          class="flex flex-row items-center w-auto font-bold cursor-pointer ml-xs md:ml-sm flex-nowrap"
          data-testid="menuToggleButton"
          @click="toggleMenuClick()"
        >
          <FaIcon icon-class="fas fa-bars" classes="mr-2" />
          {{ t('header.menuToggleButton') }}
        </div>
        <div class="visible md:hidden" :class="{ grow: shopIsEnabled }">
          <LinkBasic
            v-if="!shopIsEnabled"
            :label="t('header.vShopLink')"
            :link="config.vShopUrl"
            target="_blank"
            icon="far fa-chevron-right"
            icon-position="right"
            classes="text-vdv-base font-semibold mr-md right-0 ml-auto"
          />

          <div v-if="shopIsEnabled" class="px-sm">
            <SearchbarMobile />
          </div>
        </div>
      </div>

      <div
        v-if="!isMobileScreen"
        :key="activeNavStructureComputed.activeItem?.id"
        class="relative flex flex-row overflow-hidden"
      >
        <HorizontalScroll
          :key="activeNavStructureComputed.activeItem.id"
          arrow-class="bg-vdv-base"
        >
          <template v-for="link in mainNavLinks" :key="link.navItem.id">
            <NuxtLink
              v-if="!link.noLink"
              :data-testid="generateTestIdFromUrl(link.navItem.navUri)"
              :to="link.navItem.navUri"
              :target="link.navItem.openInNewWindow ? '_blank' : '_self'"
              :class="[
                link.cssClass,
                link.navItem === state.hoverNav.hoveredItem
                  ? '!bg-white !text-vdv-font after:!border-white'
                  : '',
                {
                  'item--active':
                    activeNavStructureComputed.activeNavItem?.id ===
                      link.navItem?.id ||
                    activeNavStructureFromStore.activeItem?.id ===
                      link.navItem?.id,
                },
              ]"
              class="snap-end"
              @mouseenter="mouseEnter($event, link)"
              @mouseleave="mouseLeave"
              @click="navItemUnhover"
            >
              <span @mouseover.stop>
                <StyledMy
                  :key="userType.currCustomerTypeId"
                  :text="
                    link.navItem.id == MyAccountIds.MY_ACCOUNT
                      ? t(userType.linkText)
                      : t(link.navItem.title)
                  "
                  :font-size-px="20"
                  :style-type="
                    getMyNavStyle(
                      link.cssClass,
                      link.navItem.id === state.hoverNav.hoveredItem?.id,
                    )
                  "
                />
              </span>
              <span v-if="linkShowsDropdownIcon(link.cssClass)" @mouseover.stop>
                <span
                  v-show="link.navItem.id === state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-up"
                    classes="text-sm ml-xs"
                  />
                </span>
                <span
                  v-show="link.navItem.id !== state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-down"
                    classes="text-sm ml-xs"
                  />
                </span>
              </span>
            </NuxtLink>
            <div
              v-else
              :class="[
                link.cssClass,
                link.navItem === state.hoverNav.hoveredItem
                  ? '!bg-white !text-vdv-font after:!border-white'
                  : '',
                'snap-end',
                'js-nav-item',
              ]"
              @click="touchClick($event, link)"
            >
              <span>{{ t(link.navItem.title) }}</span>
              <span v-if="linkShowsDropdownIcon(link.cssClass)">
                <span
                  v-show="link.navItem.id === state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-up"
                    classes="text-sm ml-xs"
                  />
                </span>
                <span
                  v-show="link.navItem.id !== state.hoverNav.hoveredItem?.id"
                >
                  <FaIcon
                    icon-class="far fa-chevron-down"
                    classes="text-sm ml-xs"
                  />
                </span>
              </span>
            </div>
          </template>
        </HorizontalScroll>
      </div>
    </div>
    <ToggleNavV2
      :show="state.toggleNav.show"
      @close="toggleMenuClick()"
    ></ToggleNavV2>
    <HoverNavV2
      :state="state.hoverNav"
      @hover="hoverMenuHover()"
      @link-click="hoverMenuUnhover()"
      @close="hoverMenuUnhover()"
    ></HoverNavV2>
  </div>
</template>

<script setup lang="ts">
import { useNav } from '@/stores/useNav';
import { storeToRefs } from 'pinia';

import ToggleNavV2 from './components/toggle-nav.vue';
import HoverNavV2 from './components/hover-nav.vue';
import HorizontalScroll from '@/components/helper/horizontal-scroll.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import StyledMy from '@/components/layout/header/components/styledMy/styledMy.vue';
import SearchbarMobile from '@/components/layout/header/components/searchbar/searchbarMobile.vue';
import config from '@/mixins/configProvider';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import useMainMavState from '../composables/useMainNavState';
import FaIcon from '@/components/fa-icon.vue';
import { MyAccountIds } from '~/@types/navigationIds';

import { useDashboardDynamicLink } from '@/composables/useDashboardDynamicLink';
const userType = useDashboardDynamicLink();

const {
  state,
  touchClick,
  mainNavLinks,
  mouseEnter,
  mouseLeave,
  navItemUnhover,
  linkShowsDropdownIcon,
  toggleMenuClick,
  hoverMenuHover,
  hoverMenuUnhover,
  getMyNavStyle,
  activeNavStructureComputed,
} = useMainMavState();
const { t } = useTrans();
const isMobileScreen = inject(mq_breakpointIsMobile);
const shopIsEnabled = useShopEnabled();

const navStore = useNav();
const { mainNav_activeStructure: activeNavStructureFromStore } =
  storeToRefs(navStore);

function generateTestIdFromUrl(url: string) {
  if (url[0] === '/') {
    url = url.slice(1, url.length);
  }
  const urlParts = url.split('-').map((part) => {
    const part1 = part[0].toUpperCase() + part.substring(1, part.length);
    return part1;
  });
  url = urlParts.join('');

  return `navItem${url}`;
}
</script>
<style scoped lang="postcss">
.item {
  @apply h-[60px] px-[20px] text-lg relative hover:bg-white hover:text-vdv-font flex flex-row items-center flex-nowrap whitespace-nowrap;
  &--parent {
    @apply breadcrumb-arrow pr-[20px];
    & + * {
      @apply pl-[30px];
    }
    &:hover {
      @apply breadcrumb-arrow--hover;
    }
  }
  &--activeParent {
    @apply breadcrumb-arrow breadcrumb-arrow--active bg-vdv-base text-white;
    &:hover {
      @apply breadcrumb-arrow--hover;
    }
    & + * {
      @apply pl-md;
    }
  }
  &--active {
    @apply bg-vdv-base text-white;
  }
  &--hidden {
    @apply hidden;
  }
}

.breadcrumb-arrow {
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 30px;
    bottom: -38px;
    right: -32px;
    box-sizing: border-box;
    z-index: 5;
    border: 17px solid black;
    border-color: transparent transparent var(--vdv-light) var(--vdv-light);
    transform-origin: 0 0;
    transform: rotate(225deg) skew(25deg, 25deg);
    box-shadow: -4px 5px 3px 0 rgb(0 0 0 / 13%);
  }
  &--active {
    &::after {
      border-color: transparent transparent var(--vdv-base) var(--vdv-base) !important;
    }
  }
  &--hover {
    &::after {
      border-color: transparent transparent white white !important;
    }
  }
}
</style>
