<template>
  <div
    class="item"
    :class="[isParent ? '' : 'item--child', itemClass]"
    @click="itemClass.includes('nolink') ? '' : emit('itemClick')"
  >
    <div class="itemIcon">
      <span v-if="navItem.icon && isParent && navItem.level === 0">
        <FaIcon :icon="navItem.icon"
      /></span>
    </div>
    <span
      v-if="navItem.children.length > 0 && canShowChildren"
      class="flex flex-row items-center w-full flex-nowrap"
    >
      <span
        ><StyledMy
          :key="userType.currCustomerTypeId"
          :font-size-px="20"
          :text="t(navItem.title)"
        ></StyledMy
      ></span>
      <FaIcon icon-class="fal fa-chevron-right" classes="shrink-0 ml-auto " />
    </span>
    <span v-else-if="navItem.level === 0">
      <span
        ><StyledMy
          :key="userType.currCustomerTypeId"
          :text="
            navItem.id == 'myAccountTemp'
              ? t(userType.linkText)
              : t(navItem.title)
          "
          :font-size-px="20"
        ></StyledMy
      ></span>
    </span>
    <NuxtLink v-else :to="navItem.navUri"
      ><StyledMy
        :key="userType.currCustomerTypeId"
        :font-size-px="20"
        :text="
          navItem.id == MyAccountIds.MY_ACCOUNT
            ? t(userType.linkText)
            : t(navItem.title)
        "
      ></StyledMy
    ></NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { type LinkedNavItem } from '@/stores/useNav';
import FaIcon from '@/components/fa-icon.vue';
import StyledMy from '@/components/layout/header/components/styledMy/styledMy.vue';
import { MyAccountIds } from '~/@types/navigationIds';
import { useDashboardDynamicLink } from '@/composables/useDashboardDynamicLink';
const userType = useDashboardDynamicLink();

/**
 * @CSS
 * Gets CSS via deep from parent (toggle-nav-v2)
 */
const props = defineProps({
  navItem: {
    type: Object as PropType<LinkedNavItem>,
    required: true,
  },
  canShowChildren: {
    type: Boolean,
    required: false,
    default: false,
  },
  isParent: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'itemClick'): void;
}>();
const { t } = useTrans();

const itemClass = getClass(props.navItem);
function getClass(navItem: LinkedNavItem) {
  if (navItem.level === 0 && navItem.slug) {
    return 'item--level0';
  } else if (navItem.level === 0 && (!navItem.slug || navItem.slug === '/')) {
    return 'item--level0--nolink';
  } else {
    return '';
  }
}
</script>
