<template>
  <div class="w-full bg-vdv-bg-base">
    <div
      class="grid max-w-full mx-auto touch:md:flex lg:flex md:max-w-screen-xl touch:md:justify-around lg:justify-around sm:grid sm:grid-cols-2 gap-sm px-md md:px-sm py-sm"
    >
      <div
        v-for="(benefit, index) in footerItems"
        :key="index"
        class="flex flex-row items-center"
      >
        <FaIcon :icon="benefit.icon" classes="fa-lg mr-xs fa-fw" />
        <p class="">{{ benefit.title }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
const footerItems = [
  {
    id: '1011',
    title: 'Rundum-Service',
    icon: {
      iconSet: 'fal-icons',
      label: 'arrow-rotate-left',
    },
  },
  {
    id: '1022',
    title: 'Persönliche Fachberatung',
    icon: {
      iconSet: 'fal-icons',
      label: 'user-tie',
    },
  },
  {
    id: '1033',
    title: 'Sicheres Onlineshopping',
    icon: {
      iconSet: 'fal-icons',
      label: 'shield-check',
    },
  },
  {
    id: '1044',
    title: 'Hochwertige Fortbildungen',
    icon: {
      iconSet: 'fal-icons',
      label: 'graduation-cap',
    },
  },
];
</script>
