<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <client-only>
    <InfoBanner
      v-if="injected?.showInfobanner && infobannerStorage && shopIsEnabled"
      @closeInfobanner="hideInfobanner"
    />
  </client-only>
  <div
    class="top-0 left-0 right-0 bg-white z-[600] shrink-0"
    :class="{
      sticky: hasStickyHeader && !isFixed,
      fixed: hasStickyHeader && isFixed,
    }"
  >
    <div
      v-if="!shopIsEnabled"
      class="flex mx-auto max-w-screen-2xl place-content-between place-items-center flex-nowrap pl-sm sm:px-sm z-100 print:max-w-[720px] print:!px-0"
    >
      <NuxtLink to="/">
        <img
          src="~/assets/images/van-der-ven-logo.svg"
          title="van der ven logo"
          alt="van der ven logo"
          class="h-[45px] w-[185px] my-3"
          data-testid="linkVDVLogo"
        />
      </NuxtLink>
      <LinkBasic
        v-if="!shopIsEnabled"
        :label="t('header.vShopLink')"
        :link="config.vShopUrl"
        target="_blank"
        icon="far fa-chevron-right"
        icon-position="right"
        classes="text-vdv-base font-semibold invisible md:visible print:hidden"
      />
    </div>
    <div
      v-else
      class="flex mx-auto max-w-screen-2xl place-content-between place-items-center flex-nowrap px-sm sm:px-sm z-100 print:max-w-[720px] print:!px-0 gap-[50px] sm:gap-0 max-h-[72px]"
    >
      <div class="max-w-[165px] min-w-[100px] w-full">
        <NuxtLink to="/">
          <img
            src="~/assets/images/van-der-ven-logo.svg"
            title="van der ven logo"
            alt="van der ven logo"
            data-testid="linkVDVLogo"
            class="h-[45px] w-[185px] my-3"
          />
        </NuxtLink>
      </div>

      <Searchbar />

      <div
        class="flex flex-row text-minilu-font print:hidden md:gap-[8px] touch:md:gap-0 desktop:lg:gap-0"
      >
        <HeaderLinks />
      </div>
    </div>
    <div
      data-print="header-line"
      class="relative bottom-0 left-0 right-0 h-2 bg-activesection-alpha print:h-[1px] print:max-w-[720px] mx-auto"
    />
  </div>
  <div
    class="hidden fixedPlaceholder shrink-0"
    :class="{
      '!block': hasStickyHeader && isFixed,
    }"
  />
  <MainNav />
  <BannerPosition :position="BannerPositions.NOTIFICATION" />
  <BannerPosition :position="BannerPositions.SALES_BAR" />
</template>
<script setup lang="ts">
import config from '@/mixins/configProvider';
import InfoBanner from '../components/infoBanner/info-banner.vue';
import MainNav from '@/components/components/navigation/vdv/main-nav.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import { useStorage } from '@vueuse/core';
import { componentVisibilitySettings as componentVisibilitySettings_IK } from '@/injectionSymbols';
import HeaderLinks from './components/header-links.vue';
import Searchbar from '../components/searchbar/searchbar.vue';
import BannerPosition from '~/components/banner/bannerPosition.vue';
import { BannerPositions } from '~/@types/bannerPositions';

const { t } = useTrans();
const injected = inject(componentVisibilitySettings_IK);
const shopIsEnabled = useShopEnabled();
const infobannerStorage = useStorage('infobannerVdv', true);

function hideInfobanner(showBanner: boolean) {
  infobannerStorage.value = showBanner;
}

const hasStickyHeader = shopIsEnabled;

const { y } = useWindowScroll();

const isFixed = computed(() => y.value > 200);
</script>
<style lang="postcss" scoped>
.fixedPlaceholder {
  @apply w-full shrink-0;
  height: var(--sticky-header-height);
}
</style>
