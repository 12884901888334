<template>
  <div class="w-full h-full">
    <template v-if="dialog == EContactView.CONTACT">
      <DialogInline
        :views="contactViews"
        @handle-closing-from-inline="handleClosingWidgetFromInlineDialog"
      >
        <template #view_1>
          <div
            class="flex-1 overflow-y-auto text-sm p-sm md:p-md bg-vdv-bg-full sm:text-md"
          >
            <div class="flex w-full pb-md gap-md">
              <img
                v-if="!showSalesPerson"
                src="@/assets/images/ratingen_van_maren.jpg"
                class="w-[80px] h-[80px] object-cover"
                loading="lazy"
              />
              <img
                v-else
                :src="salesPerson.image.mobile.url"
                class="w-[80px] h-[80px] object-cover object-top"
                loading="lazy"
              />
              <div>
                <div v-if="showSalesPerson">
                  <b>{{ salesPerson.jobPosition }}</b>
                  <p class="pt-[5px]">
                    {{ salesPerson.firstName }} {{ salesPerson.lastName }}
                  </p>
                  <div v-if="salesPerson.phone">
                    <p>
                      <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                      <a :href="`tel:${salesPerson.phone}`">
                        {{ salesPerson.phone }}
                      </a>
                    </p>
                  </div>
                  <p v-if="salesPerson.email">
                    <LinkBasic
                      label="Nachricht schreiben"
                      :link="`mailto:${salesPerson.email}`"
                      target="_blank"
                      icon="fas fa-chevron-right"
                      class="text-primary-base"
                    />
                  </p>
                </div>
                <p :class="showSalesPerson ? 'pt-sm' : ''"><b>Empfang</b></p>
                <div class="pt-[5px] pb-sm">
                  <p>
                    <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                    <a href="tel:0210213380">02102 1338-0</a>
                  </p>
                </div>
                <b>Materialbestellung </b>
                <div class="pt-[5px] pb-sm">
                  <p>
                    <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                    <a href="tel:0210213384949">02102 1338-4949</a>
                  </p>
                </div>
                <b>Technischer Service</b>
                <div class="pt-[5px] pb-sm">
                  <p>
                    <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                    <a href="tel:021021338275">02102 1338-275</a>
                  </p>
                </div>
                <b>Servicecenter für Werkstatt</b>
                <div class="pt-[5px] pb-sm">
                  <p>
                    <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                    <a href="tel:021021338375">02102 1338-375</a>
                  </p>
                </div>
                <b>Buchhaltung</b>
                <div class="pt-[5px] pb-sm">
                  <p>
                    <FaIcon classes="mr-2" icon-class="fal fa-phone" />
                    <a href="tel:0210213384955">02102 1338-4955</a>
                  </p>
                </div>
                <b class="block mt-md">Servicezeiten</b>
                <div class="table pt-sm">
                  <div class="table-row">
                    <div class="table-cell pr-3 leading-7">Mo - Do</div>
                    <div class="table-cell leading-7 text-right">
                      8:00 - 16:45 Uhr
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="table-cell pr-3 leading-7">Freitag</div>
                    <div class="table-cell leading-7 text-right">
                      8:00 - 15:15 Uhr
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ButtonBasic
              :full-width="true"
              :type="EButtonType.ROUTERLINK"
              label="Zum Kontaktformular"
              class="mb-md h-9"
              :btn-style="EButtonStyle.LIGHT"
              target="/kontakt"
              :testid="contactButtonTestId"
              @click="emit('close')"
            />
            <p class="leading-7">
              Auf unserer
              <LinkBasic
                label="FAQ-Seite"
                link="/faq"
                target="_self"
                class="font-bold text-vdv-base"
                @click="emit('close')"
              />
              beantworten wir Ihnen häufig gestellte Fragen.
            </p>
          </div>
        </template>
      </DialogInline>
    </template>
    <template v-if="dialog == EContactView.ERROR">
      <DialogInline
        :views="errorViews"
        :dialog="dialog"
        @handle-closing-from-inline="handleClosingWidgetFromInlineDialog"
      >
        <template #view_1>
          <div class="flex-1 h-full p-md bg-vdv-bg-full">
            <b>Der schnellste Weg zur Hilfe</b>
            <p class="pt-sm">
              Nutzen Sie unser Online-Formular, um eine Störung an einem Gerät
              zu melden.
            </p>

            <BulletList
              :list-type="EListType.CheckMarksDarkGreen"
              :list-items="list"
              class="pt-md"
            />
            <ButtonBasic
              :full-width="true"
              :type="EButtonType.ROUTERLINK"
              label="Zum Störungsformular"
              class="mt-md h-9"
              :btn-style="EButtonStyle.LIGHT"
              target="/technischer-service/reparatur/stoerungsmelder"
              @click="emit('close')"
            />
          </div>
        </template>
      </DialogInline>
    </template>
    <template v-if="dialog == EContactView.BANNER">
      <DialogInline
        :views="bannerViews"
        :dialog="dialog"
        @handle-closing-from-inline="handleClosingWidgetFromInlineDialog"
      >
        <template #view_1>
          <WidgetNotificationBanner
            :banner-data="
              assignedBanners[0].banner as WidgetNotificationBannerData
            "
            class="overflow-y-auto"
          />
        </template>
      </DialogInline>
    </template>
  </div>
</template>

<script setup lang="ts">
import { EContactView } from '@/@types/contactview-type';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import { EListType } from '@/@types/bullet-list';
import type { IListItem } from '@/@types/bullet-list';
import DialogInline from '~~/src/components/components/widgets/vdv/inline-dialog.vue';
import BulletList from '@/components/components/core/bullet-list/bullet-list.vue';
import ButtonBasic from '~~/src/components/components/core/basic-button/vdv/basic-button.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import FaIcon from '@/components/fa-icon.vue';
import { useAssignedBanners } from '~/composables/banner/useAssignedBanners';
import { BannerPositions } from '~/@types/bannerPositions';
import { BannerTypeHandle } from '~/components/banner/types';
import type { WidgetNotificationBannerData } from '~/components/banner/components/banners/widgetNotification/type';
import WidgetNotificationBanner from '~/components/banner/components/banners/widgetNotification/widgetNotificationBanner.vue';
import { useUserContext } from '~/stores/useUserContext';
import { useSessionStore } from '~/stores/useSessionStore';
import type { PersonData } from '~/server/transformers/EntityTransformers/personTransformer';
import { useSessionGet } from '~/composables/dataFetching/genericFetchers';

const userContext = useUserContext();
const sessionStore = useSessionStore();
const showSalesPerson = ref();
const salesPerson = ref();

watch(
  [() => sessionStore.isLoggedIn, () => userContext.accountData?.salesPersonId],
  async ([isLoggedIn, salesPersonId]) => {
    if (isLoggedIn && salesPersonId) {
      salesPerson.value = await useSessionGet<PersonData>(
        `/api/${useSiteIdent()}/content/salesPerson/${salesPersonId}`,
      );
      if (salesPerson.value) showSalesPerson.value = true;
    } else {
      showSalesPerson.value = false;
    }
  },
  { immediate: true },
);

defineProps({
  dialog: {
    type: String as PropType<EContactView>,
    default: () => {
      [
        {
          id: '1',
          title: 'Kontakt',
        },
      ];
    },
  },
  contactButtonTestId: {
    type: String,
    required: false,
    default: '',
  },
});

const list = ref([
  {
    text: 'Direkte Hilfestellung zu Ihrem konkreten Problem',
  },
  {
    text: 'Umgehende persönliche Betreuung bei akuten Problemen innerhalb unserer Servicezeiten',
  },
] as IListItem[]);

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'handleClosing'): void;
}>();

function handleClosingWidgetFromInlineDialog(): void {
  emit('handleClosing');
}

const contactViews = ref([
  {
    id: '1',
    title: 'Kontakt',
  },
]);

const { assignedBanners } = await useAssignedBanners(
  BannerPositions.WIDGET_NOTIFICATION,
);

const errorViews = ref([
  {
    id: '1',
    title: 'Störung melden',
    bgColor: ' bg-sign-yellow',
  },
]);

const bannerViews = computed(() => {
  const banner = assignedBanners.value?.[0]
    ?.banner as WidgetNotificationBannerData;
  if (banner && banner.typeHandle === BannerTypeHandle.WIDGET_NOTIFICATION) {
    return [
      {
        id: '1',
        title: banner.headline,
        bgColor: ' !bg-status-danger-base',
      },
    ];
  }
  return [];
});
</script>
