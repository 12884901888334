<template>
  <ClientOnly>
    <div class="overflow-hidden toggleNav">
      <!--Background Overlay-->
      <div
        v-show="show"
        class="fixed z-40 top-0 left-0 sm:w-[100%] h-[100%] overlay overflow-hidden"
        @click="emit('close')"
      ></div>
      <!--Nav-->
      <div
        :class="show ? 'w-full sm:w-[375px]' : '!-left-[25%] '"
        class="fixed z-50 top-0 left-0 transition-all h-[100%] bg-white shadow-lg flex flex-col overflow-hidden p-md md:py-xl md:px-md"
      >
        <!--Nav Header-->
        <div
          v-if="state.activeNavItem !== null"
          class="absolute top-0 left-0 w-full h-full"
        >
          <!--Logo-->
          <div class="flex flex-row flex-nowrap">
            <NuxtLink to="/">
              <img
                src="~/assets/images/van-der-ven-logo.svg"
                title="van der ven logo"
                alt="van der ven logo"
                class="h-[45px] w-[185px] my-3 ml-md"
              />
            </NuxtLink>
            <ButtonIcon
              button-classes="Close Burger-Menu"
              icon="fas fa-xmark"
              classes="hover:cursor-pointer ml-auto my-auto mr-md shrink-0 fa-lg text-vdv-border-dark"
              :plain="true"
              testid="toggleNavMenuIcon"
              @click="emit('close')"
            />
          </div>
          <div
            class="relative bottom-0 left-0 right-0 h-2 bg-activesection-alpha"
          />
          <!-- login -->
          <div v-if="shopIsEnabled">
            <Login testid="toggleNaviLoginButton" @close="$emit('close')" />
          </div>

          <!-- Shop & Home Nav-->
          <div class="itemSection itemSection--border">
            <NuxtLink class="item item--child" to="/" @click="emit('close')">
              <div class="itemIcon"></div>
              <div>{{ t('toggleNav.startPage') }}</div>
            </NuxtLink>
            <NuxtLink
              v-if="!shopIsEnabled"
              to="https://www.vshop.de/"
              class="item item--child"
              @click="emit('close')"
            >
              <div class="itemIcon">
                <FaIcon icon-class="fal fa-cart-shopping" />
              </div>
              <div>{{ t('toggleNav.shop') }}</div>
            </NuxtLink>
          </div>
          <!-- Dynamic Navigation-->
          <div
            class="itemSection"
            :class="{
              'itemSection--border':
                (shopIsEnabled && session.isLoggedIn) ||
                state.activeNavItem.level === 0,
            }"
          >
            <!-- Back Btn-->
            <div
              v-show="state.activeNavItem.level !== 0"
              class="item item--child"
              @click="setNavItemFromClick(state.activeNavItem.parent)"
            >
              <div class="itemIcon">
                <FaIcon icon-class="fal fa-chevron-left" />
              </div>
              <div>{{ t('toggleNav.back') }}</div>
            </div>
            <!-- MyProducts-->
            <template v-if="state.activeNavItem.level === 0">
              <template
                v-for="(navStructure, n) of extraToplevelNavMyProducts"
                :key="n"
              >
                <!--Headline-->
                <ToggleNavItem
                  v-if="navStructure.headline && navStructure.navItems.length"
                  :key="navStructure.headline.id"
                  :nav-item="navStructure.headline"
                  :is-parent="true"
                  :class="{
                    'item--active':
                      state.activeNavItem.id ===
                        activeNavStructure.activeItem.id &&
                      state.activeNavItem.level !== 0,
                  }"
                  @item-click="
                    setNavItemFromClick(
                      navStructure.headline,
                      navStructure.headline.children?.length > 0,
                    )
                  "
                />
                <!--NavItems-->
                <template
                  v-for="navItem of navStructure.navItems"
                  :key="navItem.id"
                >
                  <ToggleNavItem
                    v-show="showItem(navItem, state.activeNavItem.id)"
                    :nav-item="navItem"
                    :can-show-children="true"
                    :class="{
                      'item--active':
                        navItem.id === activeNavStructure.activeItem.id,
                    }"
                    @item-click="
                      setNavItemFromClick(
                        navItem,
                        navItem.children.length === 0,
                      )
                    "
                  />
                </template>
              </template>
            </template>
            <!-- Active Item-->
            <ToggleNavItem
              :key="state.activeNavItem.id"
              :nav-item="state.activeNavItem"
              :is-parent="true"
              :class="{
                'item--active':
                  state.activeNavItem.id === activeNavStructure.activeItem.id &&
                  state.activeNavItem.level !== 0,
              }"
              @item-click="setNavItemFromClick(state.activeNavItem, true)"
            />
            <!-- Active Item Children-->
            <ToggleNavItem
              v-for="child in state.activeNavItem.children"
              v-show="showItem(child, state.activeNavItem.id)"
              :key="child.id"
              :nav-item="child"
              :can-show-children="true"
              :class="{
                'item--active': child.id === activeNavStructure.activeItem.id,
              }"
              @item-click="
                setNavItemFromClick(child, child.children.length === 0)
              "
            />
            <div
              v-if="state.activeNavItem.level === 0 && shopIsEnabled"
              class="h-sm"
            />
            <!--MyAccount-->
            <template v-if="state.activeNavItem.level === 0">
              <template
                v-for="(navStructure, n) of extraToplevelNavMyAccount"
                :key="n"
              >
                <!--Headline-->
                <ToggleNavItem
                  v-if="navStructure.headline && navStructure.navItems.length"
                  :key="navStructure.headline.id"
                  :nav-item="navStructure.headline"
                  :is-parent="true"
                  :class="{
                    'item--active':
                      state.activeNavItem.id ===
                        activeNavStructure.activeItem.id &&
                      state.activeNavItem.level !== 0,
                  }"
                  @item-click="
                    setNavItemFromClick(
                      navStructure.headline,
                      navStructure.headline.children?.length > 0,
                    )
                  "
                />
                <!--NavItems-->
                <template
                  v-for="navItem of navStructure.navItems"
                  :key="navItem.id"
                >
                  <ToggleNavItem
                    v-show="showItem(navItem, state.activeNavItem.id)"
                    :nav-item="navItem"
                    :can-show-children="true"
                    :class="{
                      'item--active':
                        navItem.id === activeNavStructure.activeItem.id,
                    }"
                    @item-click="
                      setNavItemFromClick(
                        navItem,
                        navItem.children.length === 0,
                      )
                    "
                  />
                </template>
              </template>
            </template>
          </div>

          <!-- Toggle Nav from CMS; Level 0 only-->
          <div
            v-show="state.activeNavItem.level === 0"
            class="itemSection"
            :class="{
              'itemSection--border': shopIsEnabled && session.isLoggedIn,
            }"
          >
            <ToggleNavItem
              v-for="extraItem in toggleNavItems.navItems.filter(
                (x) => x.level !== 0,
              )"
              :key="extraItem.id"
              :nav-item="extraItem"
              :can-show-children="false"
              :class="{
                'item--active': extraItem.navUri === $route.path,
              }"
              @item-click="
                setNavItemFromClick(extraItem, extraItem.children.length === 0)
              "
            />
            <!--Social Media-->
            <div class="flex flex-row item item--child item--iconRow !mt-sm">
              <div class="itemIcon"></div>
              <a
                href="https://www.facebook.com/vanderVenDental"
                class="mr-sm"
                target="_blank"
                ><FaIcon
                  classes="text-2xl text-vdv-border-dark"
                  icon-class="fab fa-square-facebook"
              /></a>
              <a
                href="https://de.linkedin.com/company/van-der-ven-dental"
                class="mr-sm"
                target="_blank"
              >
                <FaIcon
                  classes="text-2xl text-vdv-border-dark"
                  icon-class="fab fa-linkedin"
              /></a>
              <a
                href="https://www.instagram.com/vandervendental/"
                class="mr-sm"
                target="_blank"
              >
                <FaIcon
                  classes="text-2xl text-vdv-border-dark"
                  icon-class="fab fa-instagram-square"
              /></a>
              <a
                href="https://www.pinterest.de/vandervendental/"
                class="mr-sm"
                target="_blank"
              >
                <FaIcon
                  classes="text-2xl text-vdv-border-dark"
                  icon-class="fab fa-square-pinterest"
              /></a>
            </div>
          </div>
          <div v-if="shopIsEnabled && session.isLoggedIn" class="itemSection">
            <div
              data-testid="toggleNaviLogoutButton"
              class="item item--child"
              @click="session.logout()"
            >
              <div class="itemIcon">
                <span class="text-minilu-border-dark">
                  <FaIcon icon-class="far arrow-right-from-bracket" />
                </span>
              </div>
              <span>
                <span>{{ t('user.loginStatus.logout') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>
<script setup lang="ts">
import { useNav, type LinkedNavItem } from '@/stores/useNav';
import { storeToRefs } from 'pinia';
import ButtonIcon from '@/components/components/core/basicButtonIcon/basic-button-icon.vue';
import ToggleNavItem from './toggle-nav-item.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';
import { showItem } from '../../utils';
import Login from './login.vue';
import { EStyleType as EStyleTypeStyledMy } from '@/components/layout/header/components/styledMy/useStyledMy';
import { getBgColor } from '@/components/layout/header/components/styledMy/vdv/utils';
import { MyAccountIds, MyProductIds } from '~/@types/navigationIds';
import { useSessionStore } from '~/stores/useSessionStore';

const navStore = useNav();
const session = useSessionStore();
const isMobile = inject(mq_breakpointIsMobile);
const shopIsEnabled = useShopEnabled();
const { t } = useTrans();

const {
  mainNav_activeStructure: activeNavStructure,
  toggleNav: toggleNavItems,
  mainNav,
} = storeToRefs(navStore);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
});

watch(
  () => props.show,
  (newVal) => {
    usePreventBodyScroll(newVal);
  },
);

onUnmounted(() => {
  usePreventBodyScroll(false);
});

const state = reactive({
  activeNavItem: null as LinkedNavItem,
});

init();

function setNavItemFromClick(navItem: LinkedNavItem, endOfStructure = false) {
  if (!navItem.navUri) {
    return;
  } else if (endOfStructure || navItem.type === 'custom') {
    emit('close');
    useRouter().push(navItem.navUri);
  } else if (!isMobile.value) {
    useRouter().push(navItem.navUri);
  } else {
    state.activeNavItem = getNewActiveItem(navItem);
  }

  function getNewActiveItem(navItem: LinkedNavItem) {
    /**
     *  Exclude Toggle-Nav Root-Item in the search because the navigation should never show this
     *  @TODO : Think about removing it from the toggle nav in useNav Store, if possible, or give it a unique ID?
     */

    if (
      toggleNavItems.value.navItems.find(
        (x) => x.id === navItem.id && x.id !== '0',
      )
    ) {
      return activeNavStructure.value.activeItem;
    } else {
      return navItem.children.length > 0 ? navItem : navItem.parent;
    }
  }
}

function init() {
  state.activeNavItem =
    activeNavStructure.value.activeItem.children.length > 0
      ? activeNavStructure.value.activeItem
      : activeNavStructure.value.activeItem.parent;
}

watch(activeNavStructure, async () => {
  init();
});

/**
 * @workaround - fastest way to seperate myProducts and myAccount
 * */
const extraToplevelNavMyProducts = computed(() => {
  const topLevelNavMap: {
    headline: LinkedNavItem;
    navItems: LinkedNavItem[];
  }[] = [];

  const myProduct = mainNav.value.navItems.filter(
    (x) => x.level === 1 && x.id === MyProductIds.MY_PRODUCTS,
  )[0];
  if (myProduct) {
    topLevelNavMap.push({
      headline: getHeadlineItem(
        'myProductTemp',
        'navigation.myProducts.myProducts',
        '/productlists',
      ),
      navItems: myProduct.children ?? [],
    });
  }

  return topLevelNavMap;

  function getHeadlineItem(
    id: string,
    title: string,
    slug: string,
    icon?: { label: string; iconSet: string },
  ): LinkedNavItem {
    return {
      type: 'custom',
      id: id,
      title: title,
      description: null,
      url: slug,
      uri: slug,
      slug: slug,
      navUri: slug,
      ad: null,
      openInNewWindow: false,
      level: 0,
      areaId: null,
      icon: icon ?? null,
      parentId: null,
      childIds: [],
      isHidden: false,
      isShopItem: true,
      parent: null,
      children: [],
      neighbours: [],
    };
  }
});

/**
 * @Info myAccount Links sollen unterhalb der Services stehen, daher müssen die Bereiche getrennt werden
 */
const extraToplevelNavMyAccount = computed(() => {
  const topLevelNavMap: {
    headline: LinkedNavItem;
    navItems: LinkedNavItem[];
  }[] = [];

  const myAccount = mainNav.value.navItems.filter(
    (x) => x.level === 1 && x.id === MyAccountIds.MY_ACCOUNT,
  )[0];
  if (myAccount) {
    topLevelNavMap.push({
      headline: getHeadlineItem(
        'myAccountTemp',
        'navigation.myAccount.myAccount',
        '/account',
        {
          label: myAccount.icon.label,
          iconSet: myAccount.icon.iconSet,
        },
      ),
      navItems: myAccount.children ?? [],
    });
  }

  return topLevelNavMap;

  function getHeadlineItem(
    id: string,
    title: string,
    slug: string,
    icon: { label: string; iconSet: string },
  ): LinkedNavItem {
    return {
      type: 'custom',
      id: id,
      title: title,
      description: null,
      url: slug,
      uri: slug,
      slug: slug,
      navUri: slug,
      ad: null,
      openInNewWindow: false,
      level: 0,
      areaId: null,
      icon: icon,
      parentId: null,
      childIds: [],
      isHidden: false,
      isShopItem: true,
      parent: null,
      children: [],
      neighbours: [],
    };
  }
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const myDecoColorDefault = getBgColor(EStyleTypeStyledMy.default);
const myDecoColorLight = getBgColor(EStyleTypeStyledMy.light);
const myDecoColorLightAlt = getBgColor(EStyleTypeStyledMy.lightAlt);
</script>

<style scoped lang="postcss">
.toggleNav:deep(> *) {
  @apply z-[640] overflow-auto overscroll-contain;
  .item {
    @apply flex flex-row cursor-pointer flex-nowrap text-md font-bold py-xs px-md hover:!bg-vdv-alpha hover:!text-vdv-font items-center;
    & #styledMy {
      @apply !fill-[v-bind('myDecoColorDefault')];
    }
    &:hover {
      & #styledMy {
        @apply !fill-[v-bind('myDecoColorLightAlt')];
      }
    }

    &--child {
      @apply font-normal;
    }
    &--iconRow {
      @apply hover:!bg-transparent cursor-default;
    }
    &--level0 {
      @apply text-vdv-font !text-lg;
      &:hover {
        & #styledMy {
          @apply !fill-[v-bind('myDecoColorLightAlt')];
        }
      }
    }
    &--level0--nolink {
      @apply !cursor-default hover:!bg-white text-vdv-font !text-lg;
    }
    &--active {
      @apply bg-vdv-base text-white;

      & #styledMy {
        @apply !fill-[v-bind('myDecoColorLight')];
      }

      &:hover {
        & #styledMy {
          @apply !fill-[v-bind('myDecoColorLightAlt')];
        }
        & .itemIcon {
          @apply !text-vdv-font;
        }
      }

      & .itemIcon {
        @apply !text-white;
      }
    }
  }

  .itemIcon {
    @apply w-md mr-2xs shrink-0 text-vdv-border-dark;
  }

  .itemSection {
    @apply py-sm relative overflow-hidden;
    &--border {
      &::after {
        content: '';
        @apply border-b-[1px] absolute bottom-0 border-solid border-vdv-border-light h-[1px] ml-md w-[calc(100%-60px)];
      }
    }
  }
}
.overlay {
  background: transparent linear-gradient(180deg, #000000 0%, #346d7300 100%) 0%
    0%;
  opacity: 0.2;
}
</style>
