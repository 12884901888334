<template>
  <FooterBanner />
  <section class="w-full print:hidden shrink-0">
    <NewsletterBanner class="mb-sm" />
    <!-- Benefits & AreaColor-Bar -->
    <Benefits />
    <div class="w-full h-2 mb-xl bg-activesection-alpha" />
    <!-- Desktop-Nav-->
    <div
      v-if="isDesktopView"
      class="grid w-full max-w-screen-xl grid-cols-12 mx-auto mb-md md:mb-lg gap-md px-sm"
    >
      <div
        v-for="(navigationItem, index) in footerNavItems"
        :key="navigationItem.id"
        :class="getClassesForMainCols(index)"
      >
        <div class="flex flex-col">
          <div
            v-if="index !== 0"
            class="truncate h-[24px] !overflow-visible mb-[25px] w-fit text-xl"
          >
            {{ navigationItem.title }}
          </div>
          <!-- First entry gets VDV Logo as headline-->
          <div v-else class="leading-xl h-[24px] !overflow-visible mb-[25px]">
            <NuxtLink to="/">
              <img
                title="van der ven logo"
                alt="van der ven logo"
                src="~/assets/images/van-der-ven-logo.svg"
                class="h-lg -mt-xs"
                loading="lazy"
              />
            </NuxtLink>
          </div>
          <hr class="w-full h-2 border-t-2 mb-[25px]" />
          <div v-if="index !== 4" class="-mt-2xs">
            <ul
              v-for="(
                navigationItem_children, index_navigationItem_children
              ) in navigationItem.children"
              :key="index_navigationItem_children"
              class="flex list-none"
            >
              <li class="text-md mb-[12px] decoration-vdv-base">
                <LinkBasic
                  :link="navigationItem_children.navUri"
                  :label="navigationItem_children.title"
                  :target="
                    navigationItem_children.openInNewWindow ? '_blank' : '_self'
                  "
                />
              </li>
            </ul>
          </div>
          <!-- Last entries children get rendered in two columns-->
          <div v-else-if="index === 4" class="grid grid-cols-2 gap-x-sm">
            <div
              v-for="(
                navigationItem_children, index_navigationItem_children
              ) in navigationItem.children"
              :key="index_navigationItem_children"
              class="col-span-1"
            >
              <div class="text-md mb-[12px] decoration-vdv-base">
                <LinkBasic
                  :link="navigationItem_children.navUri"
                  :label="navigationItem_children.title"
                  :target="
                    navigationItem_children.openInNewWindow ? '_blank' : '_self'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile-Nav => Accordions-->
    <div
      v-if="!isDesktopView"
      class="grid max-w-screen-xl grid-rows-1 mx-auto gap-sm px-md md:px-sm mb-lg"
    >
      <NuxtLink to="/">
        <img
          title="van der ven logo"
          alt="van der ven logo"
          src="~/assets/images/van-der-ven-logo.svg"
          class="h-lg"
          loading="lazy"
        />
      </NuxtLink>
      <AccordionMulti :accordion-items="accordion"></AccordionMulti>
    </div>
    <!--Lower Footer => Language-select, Social Media, Slogan-->
    <div class="w-full bg-vdv-base">
      <div
        class="flex flex-col items-center max-w-screen-xl grid-cols-12 mx-auto text-white md:flex-row gap-md px-md md:px-sm py-lg md:justify-end"
      >
        <div class="flex flex-row items-center justify-center md:justify-end">
          <p class="mr-xs">Folgen Sie uns</p>
          <a
            href="https://www.facebook.com/vanderVenDental"
            target="_blank"
            title="Facebook Page"
            ><FaIcon classes="fa-2x mr-xs" icon-class="fab fa-square-facebook"
          /></a>
          <a
            title="LinkedIn Page"
            href="https://de.linkedin.com/company/van-der-ven-dental"
            target="_blank"
          >
            <FaIcon classes="fa-2x mr-xs" icon-class="fab fa-linkedin"
          /></a>
          <a
            title="Instagram Page"
            href="https://www.instagram.com/vandervendental/"
            target="_blank"
          >
            <FaIcon classes="fa-2x mr-xs" icon-class="fab fa-instagram-square"
          /></a>
        </div>
        <div class="flex items-center justify-center md:justify-start">
          <img
            title="van der ven logo"
            alt="van der ven logo"
            src="~/assets/images/vdv-v-bubble-inverse.svg"
            class="h-[36px] mr-xs"
            loading="lazy"
          />
          <img
            title="van der ven logo"
            alt="van der ven logo"
            src="~/assets/images/vdv_footer_menschzumensch_claim_white.svg"
            class="h-[13px]"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useNav } from '@/stores/useNav';
import AccordionMulti from '@/components/components/core/accordion/vdv/accordion-multi.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import Benefits from './components/benefits.vue';
import NewsletterBanner from './components/newsletter-banner.vue';
import FaIcon from '@/components/fa-icon.vue';
import FooterBanner from '../components/footerBanner.vue';

const navStore = useNav();
const { footerNav: footerNavData } = storeToRefs(navStore);

const footerNavItems = computed(() => {
  return footerNavData.value.navItems.filter((x) => x.level === 1);
});

const isDesktopView = useIsDesktopView();

function getClassesForMainCols(index: number): string {
  if (index == 0) {
    return 'sm:col-span-6 desktop:md:col-span-4 touch:md:col-span-2 desktop:lg:col-span-2 mb-md';
  } else if (index % 4 == 0) {
    return 'sm:col-span-12 desktop:md:col-span-8 touch:md:col-span-4 desktop:lg:col-span-4 mb-md';
  } else if (index >= 1 && index <= 3) {
    return 'sm:col-span-6 desktop:md:col-span-4 touch:md:col-span-2 desktop:lg:col-span-2 mb-md';
  } else {
    //Hidden because for now we only want one full row of footer items
    return 'hidden sm:col-span-6 desktop:md:col-span-4 touch:md:col-span-2 desktop:lg:col-span-2 mb-md';
  }
}

const accordion = computed(() => {
  const accordionItems = [];

  for (const navItem of footerNavItems.value) {
    const accordionItem = {
      titleSlot: `${navItem.title}`,
      classes: 'mb-sm',
      activeBarTextColor: 'text-white',
      contentSlot: '',
    };

    for (const navItemChild of navItem.children) {
      const link = navItemChild.navUri;
      const title = navItemChild.title;
      const openInNewWindow = navItemChild.openInNewWindow;

      const content = `
      <div class="w-full mb-sm !text-vdv-font [&_*]:!text-vdv-font">
        <a href="${link}" target="${openInNewWindow ? '_blank' : '_self'}">
          ${title}
        </a>
      </div>
      `;

      accordionItem.contentSlot += content;
    }
    accordionItems.push(accordionItem);
  }
  return accordionItems;
});
</script>
